import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { CisAssetTypeService } from "src/app/Services/CIS-AssetType.services";
import { CisLevelService } from "src/app/Services/cis-level.service";
import { ControlFunctions } from "src/app/Services/control-functions.service";
import { ControlTypeService } from "src/app/Services/control-type.service";
import { DocumentTypeService } from "src/app/Services/document-type.services";
import { DocumentService } from "src/app/Services/document.service";
import { InternalControlTypeService } from "src/app/Services/internal-Control-Type.service";
import { PrimaryObjectivesService } from "src/app/Services/primary-objectives.service";
import { ScfDomainsService } from "src/app/Services/scfDomains.service";
import { SuggestedControlService } from "src/app/Services/suggested-controls.service";

/// mat chip input imports
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { ListHelper } from "src/app/ListHelperCLasses/ListHelper";
import { ControlModel } from "src/app/Models/ControlModel";
import { ChapterService } from "src/app/Services/chapter.service";
import { ControlService } from "src/app/Services/control.service";
import { NotificationService } from "src/app/Services/notification.service";
import { SuggestedControlOwnershipService } from "src/app/Services/suggested-control-ownership.service";
@Component({
  selector: "app-question-filter",
  templateUrl: "./question-filter.component.html",
  styleUrls: ["./question-filter.component.scss"],
})
export class QuestionFilterComponent implements OnInit {
  @Input() onClose: (closeboth) => {};
  @Output() addedfileters = new EventEmitter<ControlModel>();
  chapters = new UntypedFormControl("");
  DocumentFormControl = new UntypedFormControl();
  ControlTypeFormControl = new UntypedFormControl("");
  CisAssetFormControl = new UntypedFormControl("");
  CislevelFormControl = new UntypedFormControl("");
  PrimaryObjectiveFormControl = new UntypedFormControl("");
  ControlFunctionFormControl = new UntypedFormControl("");
  SuggestedControlFormControl = new UntypedFormControl("");
  ScfDomainsFormControl = new UntypedFormControl("");
  SuggestedControlOwnershipFormControl = new UntypedFormControl("");

  chapterId: string;
  chaptersList: ListHelper[] = [];

  isLoading = false;
  controlModel: ControlModel = new ControlModel();

  controlId: string;
  controlForm: UntypedFormGroup;
  doctypelist: any;
  documentlist: any;
  controlTypelist: any;
  cisAssetTypelist: any;
  cisLevellist: any;
  controlFunctionlist: any;
  scfdomainslist: any;
  primaryObjectiveslist: any;
  suggestedControlOwnershiplist: any;
  suggestedControllist: any;

  documentId: string;
  chaptersIds: string[] = [];
  controltypeids: string[] = [];
  cisassettypeids: string[] = [];
  controlfunctionids: string[] = [];
  cislevelids: string[] = [];
  primaryobjectiveids: string[] = [];
  suggestedcontrolids: string[] = [];
  suggestedcontrolownershipids: string[] = [];
  scfdomainids: string[] = [];

  //#endregion
  constructor(
    private documentTypeService: DocumentTypeService,
    private documentService: DocumentService,
    private controlTypeService: ControlTypeService,
    private cisAssetTypeService: CisAssetTypeService,
    private cisLeveService: CisLevelService,
    private controlFunctionService: ControlFunctions,
    private primayObjectiveService: PrimaryObjectivesService,
    private suggestedControlService: SuggestedControlService,
    private scfDomainService: ScfDomainsService,
    private internalControlService: InternalControlTypeService,
    private chapterService: ChapterService,
    private controlService: ControlService,
    private router: Router,
    private route: ActivatedRoute,
    private suggestedControlOwnershipService: SuggestedControlOwnershipService,
    private locationService: Location,
    private notify: NotificationService
  ) {
    this.loadInitialData();
  }
  ngOnInit(): void {
    this.route.queryParamMap.subscribe((data) => {
      if (data.get("docId")) {
        this.documentId = data.get("docId");
      }
    });

    let QuestionFilters = JSON.parse(localStorage.getItem('QuestionFilters'));

    if (QuestionFilters != null) {
      this.isLoading = true;
      this.documentId = QuestionFilters.documentId
      this.loadChapter()
      this.chaptersIds = QuestionFilters.chapterIds.map(x => x.id)
      this.controltypeids = QuestionFilters.controlTypeIds.map(x => x.id)
      this.primaryobjectiveids = QuestionFilters.PrimaryObjectiveIds.map(x => x.id)
      this.controlfunctionids = QuestionFilters.controlFunctionIds.map(x => x.id)
      this.suggestedcontrolids = QuestionFilters.suggestedControlIds.map(x => x.id)
      this.suggestedcontrolownershipids = QuestionFilters.ScoIds.map(x => x.id)
      this.scfdomainids = QuestionFilters.scfDomainIds.map(x => x.id)
      this.cislevelids = QuestionFilters.cisLevelIds.map(x => x.id)
      this.cisassettypeids = QuestionFilters.cisAssetTypeIds.map(x => x.id)
    }
  }

  //load data for dropdowns
  loadInitialData() {
    this.isLoading = true;

    // document dropdown
    this.documentService.getList().subscribe((result) => {
      this.documentlist = result;
    });
    // control type ddp
    this.controlTypeService.getAll().subscribe((result) => {
      this.controlTypelist = result;
    });

    // cis asset type ddp
    this.cisAssetTypeService.getAll().subscribe((result) => {
      this.cisAssetTypelist = result;
    });

    // cis level ddp
    this.cisLeveService.getAllCISLevels().subscribe((result) => {
      this.cisLevellist = result;
    });

    // control functions ddp

    this.controlFunctionService.getAll().subscribe((result) => {
      this.controlFunctionlist = result;
    });

    // primary objective ddp

    this.primayObjectiveService.getAll().subscribe((result) => {
      this.primaryObjectiveslist = result;
    });

    // suggested control ddp

    this.suggestedControlService.getAll().subscribe((result) => {
      this.suggestedControllist = result;
    });

    // // suggested control ownership ddp

    this.suggestedControlOwnershipService.getAll().subscribe((result) => {
      this.suggestedControlOwnershiplist = result;
    });

    // scf domains ddp

    this.scfDomainService.getAll().subscribe((result) => {
      this.scfdomainslist = result;
      this.isLoading = false;
    });
  }

  applyFilters() {

    this.controlModel.controlTypeIds = [];
    this.controlModel.cisAssetTypeIds = [];
    this.controlModel.controlFunctionIds = [];
    this.controlModel.PrimaryObjectiveIds = [];
    this.controlModel.suggestedControlIds = [];
    this.controlModel.suggestedControlIds = [];
    this.controlModel.ScoIds = [];
    this.controlModel.scfDomainIds = [];
    this.controlModel.cisLevelIds = [];
    this.controlModel.chapterIds = [];
    this.controlModel.documentId = this.documentId;


    if (this.controltypeids != null) {
      this.controltypeids.forEach((row) => {
        this.controlModel.controlTypeIds.push({
          id: row,
        });
      });
    }
    if (this.cisassettypeids != null) {
      this.cisassettypeids.forEach((row) => {

        this.controlModel.cisAssetTypeIds.push({
          id: row,
        });
      });
    }

    if (this.controlfunctionids != null) {
      this.controlfunctionids.forEach((row) => {
        this.controlModel.controlFunctionIds.push({
          id: row,
        });
      });
    }

    if (this.primaryobjectiveids != null) {
      this.primaryobjectiveids.forEach((row) => {
        this.controlModel.PrimaryObjectiveIds.push({
          id: row,
        });
      });
    }

    if (this.suggestedcontrolids != null) {
      this.suggestedcontrolids.forEach((row) => {
        this.controlModel.suggestedControlIds.push({
          id: row,
        });
      });
    }

    if (this.suggestedcontrolownershipids != null) {
      this.suggestedcontrolownershipids.forEach((row) => {
        this.controlModel.ScoIds.push({
          id: row,
        });
      });
    }

    if (this.scfdomainids != null) {

      this.scfdomainids.forEach((row) => {
        this.controlModel.scfDomainIds.push({
          id: row,
        });
      });
    }

    if (this.cislevelids != null) {
      this.cislevelids.forEach((row) => {
        this.controlModel.cisLevelIds.push({
          id: row,
        });
      });
    }

    if (this.chaptersIds != null) {
      this.chaptersIds.forEach((row) => {
        this.controlModel.chapterIds.push({
          id: row,
        });
      });
    }
    localStorage.setItem('QuestionFilters', JSON.stringify(this.controlModel));
    this.addedfileters.emit(this.controlModel);
    this.onClose(false);

  }

  loadChapter() {
    if (this.documentId == "") {
      this.chaptersList = [];
      return;
    }
    this.chapterService.getLeafChilds(this.documentId).subscribe((res) => {
      this.chaptersList = res;
    });
  }

  resetFilters() {
    this.chaptersIds = null;
    this.documentId = null;
    this.controltypeids = null;
    this.cisassettypeids = null;
    this.controlfunctionids = null;
    this.primaryobjectiveids = null;
    this.suggestedcontrolids = null;
    this.suggestedcontrolownershipids = null;
    this.scfdomainids = null;
    this.cislevelids = null;
    this.controlModel = null;

    localStorage.setItem('ControlFilters', JSON.stringify(this.controlModel));
  }
}
