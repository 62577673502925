<!-- <ng-container >
    <app-atlas-table  [columns]="columns" [dataSource]="controls"
    [customColumnWidths]="true" [customPageSizeOptions]="customPageSizeOptions"
    [hasPreviewIcon]="true"
    [hasDeleteIcon]="false"
    [onOpen]="OpenControlDetails.bind(this)"
        >
</app-atlas-table>
</ng-container> -->

<div class="row" *ngIf="!isLoading">
    <div class="col-md-12 mb-2">
        <strong class="ps-2 ">Control's List</strong>
    </div>
    <form [formGroup]="questionsControlFormGroup">
        <div formArrayName="controlsArray">
            <div class="row" *ngFor="let control of controls let i = index" formGroupName="{{i}}">
                <button class="collapsiblebtn mb-1" (click)="collapse(control.id)">
                    <mat-icon class="arrowIcon">{{getArrowIcon(control.id)}}</mat-icon>
                    <mat-icon class="arrowIcon" style="font-size: 16px;"
                        (click)="$event.stopPropagation(); openConctrolDetails(control)">open_in_new
                    </mat-icon>
                    <span class="controlsHeading"> {{control.documentName}} - {{control.chapter}} -
                        {{control.title}}
                    </span>
                </button>
                <div class="collapsible" [id]="control.id">

                    <input formControlName="controlId" hidden>
                    <div class="card-body" style="display:inline-block ;">
                        <strong>Chapter</strong>
                        <p class="card-text">{{control.chapter}}</p>
                    </div>
                    <div class="card-body" style="display:inline-block ;">
                        <strong class="inline-block" style="vertical-align: top;">Chapter Description</strong>
                        <mat-icon class="inline-block" style="vertical-align: top; font-size: 16px; cursor: pointer;"
                            *ngIf="control.chapterDescription"
                            [matMenuTriggerFor]="htmlMenuControlChapterDescription">add</mat-icon>
                        <mat-menu *ngIf="control.chapterDescription" #htmlMenuControlChapterDescription="matMenu"
                            class="custom-mat-menu">
                            <p class="card-text p-3 matMenuModuleWidth" [innerHTML]="control.chapterDescription"></p>
                            <!-- Add more menu items here -->
                        </mat-menu>
                        <br>
                        <label>{{' '}}</label>
                    </div>
                    <div class="card-body" style="display:inline-block ;">
                        <strong>Document</strong>
                        <p class="card-text">{{control.document}}</p>
                    </div>
                    <div class="card-body" style="display:inline-block ;">
                        <strong class="inline-block" style="vertical-align: top;">SCF Domain</strong>
                        <mat-icon class="inline-block" style="vertical-align: top; font-size: 16px; cursor: pointer;"
                            [matMenuTriggerFor]="htmlMenuControlDocument">add</mat-icon>
                        <mat-menu #htmlMenuControlDocument="matMenu" class="custom-mat-menu">
                            <p class="card-text p-3 matMenuModuleWidth">{{''+ getScfDomain(control.scfDomainIds)}}</p>
                            <!-- Add more menu items here -->
                        </mat-menu>
                        <br>
                        <label>{{' '}}</label>
                    </div>
                    <div class="card-body" style="display:inline-block ;">
                        <strong class="inline-block" style="vertical-align: top;">Description</strong>
                        <mat-icon class="inline-block" style="vertical-align: top; font-size: 16px; cursor: pointer;"
                            [matMenuTriggerFor]="htmlMenuDescription">add</mat-icon>
                        <mat-menu #htmlMenuDescription="matMenu" class="custom-mat-menu">
                            <p class="card-text p-3 matMenuModuleWidth" [innerHTML]="control.description"></p>
                            <!-- Add more menu items here -->
                        </mat-menu>
                        <br>
                        <label>{{' '}}</label>
                    </div>
                    <div class="card-body" style="display:inline-block ;">
                        <strong class="inline-block" style="vertical-align: top;">Objective</strong>
                        <mat-icon class="inline-block" style="vertical-align: top; font-size: 16px; cursor: pointer;"
                            [matMenuTriggerFor]="htmlMenuObjective">add</mat-icon>
                        <mat-menu #htmlMenuObjective="matMenu" class="custom-mat-menu">
                            <p class="card-text p-3 matMenuModuleWidth" [innerHTML]="control.objective"></p>
                            <!-- Add more menu items here -->
                        </mat-menu>
                        <br>
                        <label>{{' '}}</label>
                    </div>
                    <div class="card-body" style="display:inline-block ;">
                        <strong class="inline-block" style="vertical-align: top;">Auditing Guidelines</strong>
                        <mat-icon class="inline-block" style="vertical-align: top; font-size: 16px; cursor: pointer;"
                            [matMenuTriggerFor]="htmlMenuGuidelines">add</mat-icon>
                        <mat-menu #htmlMenuGuidelines="matMenu" class="custom-mat-menu">
                            <p class="card-text p-3 matMenuModuleWidth" [innerHTML]="control.auditingGuideLines"></p>
                        </mat-menu>
                        <br>
                        <label>{{' '}}</label>
                    </div>
                    <div class="card-body" style="display:inline-block ;">
                        <strong class="inline-block" style="vertical-align: top;">Other Information</strong>
                        <mat-icon class="inline-block" style="vertical-align: top; font-size: 16px; cursor: pointer;"
                            [matMenuTriggerFor]="htmlMenuInformation">add</mat-icon>
                        <mat-menu #htmlMenuInformation="matMenu" class="custom-mat-menu">
                            <p class="card-text p-3 matMenuModuleWidth" [innerHTML]="control.otherInformation"></p>
                        </mat-menu>
                        <br>
                        <label>{{' '}}</label>
                    </div>

                    <div class="row" *ngIf="control.questionsViewModels.length > 0">
                        <div class="col-md-4"><strong>Questions</strong></div>
                        <div class="col-md-3"><strong>Answers</strong></div>
                        <div class="col-md-2"><strong>State</strong></div>
                        <div class="col-md-2"><strong>Predefined Suggested Actions</strong></div>
                    </div>
                    <div formArrayName="questions">
                        <div class="row mb-2" *ngFor="let question of control.questionsViewModels let j=index"
                            formGroupName="{{j}}">
                            <div class=" col-md-3">
                                <p>{{question.question}}</p>
                                <input formControlName="questionId" name="questionId" id="questionId" hidden>
                            </div>
                            <div class="col-md-1">
                                <i (click)="ShowQuestionDetails(question.id)">
                                    <mat-icon class="mt-2" matTooltip="Question Details">reorder</mat-icon>
                                </i>
                            </div>

                            <div class=" col-md-3">
                                <mat-form-field class="example-full-width" appearance="fill">
                                    <mat-label>Answer</mat-label>
                                    <textarea (keyup)="autoGrowTextZone($event)" matInput placeholder="Answer"
                                        name="Answer" formControlName="questionAnswer"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="col-md-2">
                                <mat-button-toggle-group formControlName="state" appearance="legacy">
                                    <mat-button-toggle value="Active">Active</mat-button-toggle>
                                    <mat-button-toggle value="NotAvailable">N/A</mat-button-toggle>
                                    <mat-button-toggle value="Done">Done</mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                            <div class="col-md-2">
                                <!-- <mat-form-field class="example-full-width" appearance="fill">
                                    <mat-label>Predefined Suggested Action</mat-label>
                                    <mat-p class="card-text" >dsdsd</mat-p>
                                     <input matInput placeholder="Actual Suggested Action" name="actualSuggestedAction"
                                        formControlName="actualSuggestedAction"> [innerHTML]="question.actualSuggestedAction"
                                </mat-form-field> -->
                                <div class="position-relative  col-md-12">
                                    <p class="card-text" [innerHTML]="question.predefinedSuggestAction"></p>

                                </div>
                            </div>
                            <div class="col-md-1" *ngIf="!auditingStatus">
                                <button (click)="openEditQuestionPopUp(i,j)"
                                    class="btn-shadow d-inline-flex align-items-center editBtnAudit">
                                    <i>
                                        <mat-icon class="mt-2">edit</mat-icon>
                                    </i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 p-2" *ngIf="!auditingStatus">
                        <button class="btn addQuestionBtn" (click)="openAddQuestionPopUp(i)">Add Question</button>
                    </div>

                    <div class="row scoureArea mb-2 p-2">
                        <div class=" col-md-3">
                            <mat-form-field appearance="fill" style="width: 100%;">
                                <mat-label>Documentation Score</mat-label>
                                <mat-select formControlName="documentScoure">
                                    <mat-option [value]="-1">None</mat-option>
                                    <mat-option *ngFor="let score of scoreList" [value]="score.value">
                                        {{score.value + ' ('+ score.name + ')'}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class=" col-md-3">
                            <mat-form-field appearance="fill" style="width: 100%;">
                                <mat-label>Implementation Score</mat-label>
                                <mat-select formControlName="implementationScore">
                                    <mat-option [value]="-1">None</mat-option>
                                    <mat-option *ngFor="let score of scoreList" [value]="score.value">
                                        {{score.value + ' ('+ score.name + ')'}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>


                        <!-- <div class="col-md-4">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Suggested Action</mat-label>
                        <input matInput placeholder="Suggested Action" name="suggestedAction" formControlName="suggestedAction">
                    </mat-form-field>
                </div> -->
                        <div class=" col-md-3">
                            <mat-form-field appearance="fill" style="width: 100%;">
                                <mat-label>Identified Criticality</mat-label>
                                <mat-select formControlName="identifiedCriticality">
                                    <mat-option *ngFor="let score of identifiedScoreList" [value]="score">
                                        {{score}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="col-md-3">
                            <app-atlas-files-list [filesList]="control.attachments" [visible]="true"
                                [visibleView]="false" [visibleDownload]="true" [visibleLink]="false"
                                [delete]="deleteFile.bind(this)" [view]="view.bind(this)"
                                [isDeletable]="isDeletable.bind(this)" [formGroupData]="documentForm"
                                (click)="bindControlId(control.id)"></app-atlas-files-list>
                            <app-atlas-file-upload [onFileUpload]="onFileUpload.bind(this)"
                                (click)="bindControlId(control.id)"></app-atlas-file-upload>
                        </div>
                    </div>
                    <div class="row scoureArea mb-2">
                        <div class="col-md-12">
                            <div class="position-relative mb-3 col-md-12">
                                <mat-label>Observations</mat-label>
                                <app-atlas-html-editor [onCreateCommand]="onCreate.bind(this)"
                                    [descriptionHTMLFormate]="getFormControlValues('observation', i)"
                                    [onChange]="predefinedObservationActionChange.bind(this, 'observation', i)"
                                    [placeholder]="'Observations'">
                                </app-atlas-html-editor>
                            </div>
                        </div>
                    </div>
                    <div class="row scoureArea mb-2">
                        <div class="col-md-12">
                            <div class="position-relative mb-3 col-md-12">
                                <mat-label>Suggested Action</mat-label>
                                <app-atlas-html-editor [onCreateCommand]="onCreate.bind(this)"
                                    [descriptionHTMLFormate]="getFormControlValues('suggestedAction', i)"
                                    [onChange]="predefinedObservationActionChange.bind(this, 'suggestedAction', i)"
                                    [placeholder]="'Predefined Suggest Action'">
                                </app-atlas-html-editor>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2" *ngIf="!auditingStatus">

                        <div class="col-md-8"></div>
                        <div class="col-md-4">
                            <button class="btn saveBtn" (click)="save(i)">Save</button>
                        </div>

                    </div>


                </div>
            </div>
        </div>


    </form>
</div>


<div *ngIf="showSpinner" class="login-spinner" style="position: absolute; top: 45vh; left: 45%;z-index: 100000;">
    <mat-spinner></mat-spinner>
</div>

<!-- Question details Component -->
<app-question-details *ngIf="openQuestionDetails" [event]="Question" [onClose]="onClose.bind(this)"
    style="position: fixed;top: 15%;width: 100%;left: 10%;z-index: 1000000;">
</app-question-details>