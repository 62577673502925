<div class="Pop-Up-Box pop-up-box">
    <div class="Pop-Up-Header">
        <span (click)="onClose(false)" class="material-icons">highlight_off</span>
        <h1>Question Details</h1>
        <div class="Pop-Up-Edit-Button">
            <button class="Edit-Button btn" (click)="getEditUrl(false)">Edit</button>
        </div>
    </div>

    <hr class="HR">

    <div class="container" >
    
        <div class="row">
            <div class="Col-Lg-12 col-lg-12">
                <h4><strong>Title</strong></h4>
                <p *ngIf="questionDetails">{{questionDetails.question}}</p>
            </div>
            <div class="Col-Lg-12 col-lg-12">
                <h4><strong>Description</strong></h4>
                <p [innerHTML]="description"> </p>
            </div>
            <div class="Col-Lg-12 col-lg-12">
                <h4><strong>Predefined Suggested Action</strong></h4>
                <p [innerHTML]="predefinedSuggestAction"> </p>
            </div>
        </div>
   
        <div class="row" *ngIf="questionDetails">
        <div class="Col-Lg-12 col-lg-12">
            <h4><strong>CIS Asset Types</strong></h4>
            <p *ngFor="let tag of questionDetails.cisAssetTypeIds" class="tag">{{tag.value}}</p>
        </div>
        <div class="Col-Lg-12 col-lg-12">
            <h4><strong>Control Types</strong></h4>
            <p *ngFor="let tag of questionDetails.controlTypeIds" class="tag">{{tag.value}}</p>
        </div>
        <div class="Col-Lg-12 col-lg-12">
            <h4><strong>Control Functions</strong></h4>
            <p *ngFor="let tag of questionDetails.controlFunctionIds" class="tag">{{tag.value}}</p>
        </div>
        <div class="Col-Lg-12 col-lg-12">
            <h4><strong>CIS Levels</strong></h4>
            <p *ngFor="let tag of questionDetails.cisLevelIds" class="tag">{{tag.value}}</p>
        </div>
        <div class="Col-Lg-12 col-lg-12">
            <h4><strong>Primary Objectives</strong></h4>
            <p *ngFor="let tag of questionDetails.primaryObjectiveIds" class="tag">{{tag.value}}</p>
        </div>
        <div class="Col-Lg-12 col-lg-12">
            <h4><strong>SCF Domains</strong></h4>
            <p *ngFor="let tag of questionDetails.scfDomainIds" class="tag">{{tag.value}}</p>
        </div>
        <div class="Col-Lg-12 col-lg-12">
            <h4><strong>Suggested Controls</strong></h4>
            <p *ngFor="let tag of questionDetails.suggestedControlIds" class="tag">{{tag.value}}</p>
        </div>
        <div class="Col-Lg-12 col-lg-12">
            <h4><strong>Suggested Control Ownership</strong></h4>
            <p *ngFor="let tag of questionDetails.scoIds" class="tag">{{tag.value}}</p>
        </div>
    </div>
    </div>
</div>