<div class="app-page-title" *ngIf="!showRevisionHistory">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="icon {{icon}}"></i>
            </div>
            <div>
                {{heading}}
                <div class="page-title-subheading">
                    {{subheading}}  
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <button *ngIf="currentRoute | hasPermission:'canCreate'"  type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success btn-atlas" routerLink="/Documentation/ControlDocumentsCreate">
                <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
                Create New
            </button>
        </div>
    </div>
  </div>
  <app-filter-function [FilterType]="filterTypeEnum" (tableSettingPopup)="tableSettingPopup()"></app-filter-function>
  <div *ngIf="!showRevisionHistory">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button  [ngClass]="[activeTab =='all' ? 'nav-link btn-atlas active' : 'nav-link']" (click)="onchangeStatusTab()"  id="pills-All-tab" >All</button>
      </li>
        <li class="nav-item" role="presentation">
          <button  [ngClass]="[activeTab =='draft' ? 'nav-link btn-atlas active' : 'nav-link']" (click)="onchangeStatusTab(appConstant.controlDocumentStatusConstants.Draft)" id="pills-home-tab" >Draft</button>
        </li>
        <li class="nav-item" role="presentation">
          <button  [ngClass]="[activeTab =='minor' ? 'nav-link btn-atlas btn active' : 'nav-link']" (click)="onchangeStatusTab(appConstant.controlDocumentStatusConstants.Minor)"  id="pills-profile-tab" >Minor</button>
        </li>
        <li class="nav-item" role="presentation">
          <button  [ngClass]="[activeTab =='major' ? 'nav-link btn-atlas btn active' : 'nav-link']" (click)="onchangeStatusTab(appConstant.controlDocumentStatusConstants.Major)"  id="pills-contact-tab" >Major</button>
        </li>
      </ul>
  </div>
  <div *ngIf="!showRevisionHistory">
    <app-atlas-table [columns]="columns" [dataSource]="data"
                     [customColumnWidths]="true" [customPageSizeOptions]="customPageSizeOptions"
                     [hasDeleteIcon]="true"
                     [hasPreviewIcon]="true"
                     [onEdit]="getEditUrl.bind(this)"
                     [pageChange]="pageChangeHandler.bind(this)"
                     [hasServerPaging]="true" [hasPaginator]="false" [totalItems]="totalItems"
                     [onDelete]="onDelete.bind(this)"   [onOpen]="onOpenDetails.bind(this)"
                        >
  
  
  
    </app-atlas-table>
  </div>
  
  <app-control-documents-details *ngIf="openDetails" [event]="selectedControlDocument" [onClose]="onCloseDetail.bind(this)"
    style="position: fixed;top: 15%;width: 100%;left: 10%;z-index: 1000000;">
  </app-control-documents-details>
 
  <div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%;">
    <mat-spinner></mat-spinner>
</div>

<app-revision-history *ngIf="showRevisionHistory" [controlDocumentId]="controlDocumentId" [onClose]="onClose.bind(this)" ></app-revision-history>

<app-compare-version-popup *ngIf="showComparisonPopup" 
   [controldocumentId]="controldocumentId" [onClose]="onClose.bind(this)"></app-compare-version-popup>

   <app-table-setting
   *ngIf="isTableSettingPopupOpen"
       [columns]="columns"
       [settingType]="settingType"
       (closePopup)="onCloseTableSettingPopup()"
       (saveSettings)="onSaveSettings()"
     ></app-table-setting>

   