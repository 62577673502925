import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { hasInvalidControl, markFormGroupTouched } from "src/app/Helpers/FormHelper";
import { linkQuestionModel, linkQuestionModelForLists } from "src/app/Models/Audit.Models";
import { ChapterService } from "src/app/Services/chapter.service";
import { ControlService } from "src/app/Services/control.service";
import { DocumentService } from "src/app/Services/document.service";
import { QuestionService } from "src/app/Services/question.service";

@Component({
  selector: "app-link-question",
  templateUrl: "./link-question.component.html",
  styleUrls: ["./link-question.component.scss"],
})
export class LinkQuestionComponent implements OnInit {
  @Output() linkQuestionOut = new EventEmitter<linkQuestionModel>();
  @Output() linkQuestionForListsOut = new EventEmitter<linkQuestionModelForLists>();
  @Input() addMultiSelect: boolean;

  documentlist: any;
  chaptersList: any;
  controllist: any;
  documentId: string;
  documnetIdList: any;
  chaptersId: string;
  chaptersIdList: string[] = [];
  controlId: string;
  controlIdList: string[] = [];
  isFromCreateDocumentPage: boolean = false;
  linkQuestionForm: UntypedFormGroup;
  linkQuestionModel: linkQuestionModel = new linkQuestionModel();
  linkQuestionModelForLists: linkQuestionModelForLists = new linkQuestionModelForLists();

  constructor(
    private documentService: DocumentService,
    private chapterService: ChapterService,
    private controlService: ControlService,
    private formBuilder: UntypedFormBuilder,
    private questionService: QuestionService
  ) {
    this.linkQuestionForm = this.formBuilder.group({
      documentId: ["", [Validators.required]],
      chapterId: ["", [Validators.required]],
      controlId: ["", [Validators.required]],
    });
  }

  @Input()
  onClose: (closeboth) => {};

  ngOnInit(): void {
    this.documentService.getList().subscribe((result) => {
      this.documentlist = result;
    });

    this.controlService.getAll().subscribe((result) => {
      this.controllist = result;
    });
  }

  loadChapter() {
    if (this.addMultiSelect) {
      console.log(this.documnetIdList);
      console.log(this.documentId);
      return;
    }
    this.chapterService.getLeafChilds(this.documentId).subscribe((res) => {
      this.chaptersList = res;
    });
  }

  loadChapterForMultipleDocuments() {
    if (this.documnetIdList != null) {
      this.chapterService.getChaptersForMultipleDocments(this.documnetIdList).subscribe(res => {
        this.chaptersList = res;
      })
    }
  }

  loadControls() {
    this.controlService
      .getControlByChapterId(this.chaptersId)
      .subscribe((res) => {
        this.controllist = res;
        console.log();
      });
  }

  loadControlsForMultipleChapters() {
    if (this.chaptersIdList != null) {
      this.controlService.getControlForMultipleChapters(this.chaptersIdList).subscribe(res => {
        this.controllist = res;
      })
    }
  }

  save() {
    if (this.linkQuestionForm.invalid || hasInvalidControl(this.linkQuestionForm)) {
      markFormGroupTouched(this.linkQuestionForm);
      return
    }
    if (this.addMultiSelect) {
      this.linkQuestionModelForLists.documentId =
        this.linkQuestionForm.get("documentId").value;
      this.linkQuestionModelForLists.chapterId =
        this.linkQuestionForm.get("chapterId").value;
      this.linkQuestionModelForLists.controlId =
        this.linkQuestionForm.get("controlId").value;
      this.linkQuestionForListsOut.emit(this.linkQuestionModelForLists);
      this.onClose(false);
    } else {
      this.linkQuestionModel.documentId =
        this.linkQuestionForm.get("documentId").value;
      this.linkQuestionModel.chapterId =
        this.linkQuestionForm.get("chapterId").value;
      this.linkQuestionModel.controlId =
        this.linkQuestionForm.get("controlId").value;
      this.linkQuestionOut.emit(this.linkQuestionModel);
      this.onClose(false);
    }
  }
}
