import { FilterType } from './Enums';

export const FilterFormConfig = {
  [FilterType.Audits]: {
    status: null,
    startDateTo: null,
    startDateFrom: null,
    endDateTo: null,
    endDateFrom: null,
    documentIds: null,
    customerIds: null
  },
  [FilterType.ControlDocuments]: {
    status: null,
    version: null,
    userIds:null,
    departmentId: null,
    category: null,
    createdDateFrom: null,
    createdDateTo: null,
  },
  [FilterType.DPA]: {
    title: null,
    createdDateFrom: null,
    createdDateTo: null,
  },
};
