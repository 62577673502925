<div class=""  *ngIf="loadView">
    <div class="card-body" style="display:inline-block ; vertical-align: top;">
        <strong class>Audit</strong>
        <p class="card-text">{{auditDetails.name}}</p>
    </div>

    <div class="card-body" style="display:inline-block ; vertical-align: top;" *ngIf="auditDetails.customer">
        <strong class>Customer</strong>
        <p class="card-text">{{auditDetails.customer}}</p>
    </div>

    <div class="card-body" style="display:inline-block ;  vertical-align: top;">
        <strong class>Start Date</strong>
        <p class="card-text">{{auditDetails.startDate}}</p>
    </div>
    <div class="card-body" style="display:inline-block ; vertical-align: top;">
        <strong class>End Date</strong>
        <p class="card-text">{{auditDetails.endDate}}</p>
    </div>
    <div class="card-body" style="display:inline-block ; vertical-align: top;">
        <strong class>Audit Status</strong>
        <p class="card-text">{{auditStatus}}</p>
    </div>
</div>