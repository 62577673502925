import { Component, HostListener, OnInit } from "@angular/core";
import { QuestionService } from "src/app/Services/question.service";
import { Column } from "src/app/Shared/form-module/atlas-forms.component";
import { faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import { QuestionsViewModel } from "src/app/Models/Audit.Models";
import { ControlService } from "src/app/Services/control.service";
import { globalConstants } from "src/constants/global-constants";
import { PageEvent } from "@angular/material/paginator";
import { HasPermissionPipe } from "src/app/Shared/pipes/HasPermissionPipe";

@Component({
  selector: "app-questions-list-table",
  templateUrl: "./questions-list-table.component.html",
  styleUrls: ["./questions-list-table.component.sass"],
  providers:[HasPermissionPipe]

})
export class QuestionsListTableComponent implements OnInit {
  heading = "Questions";
  subheading = "Questions to ask for Audit";
  icon = "pe-7s-document icon-gradient bg-premium-dark";
  isLoading = false;
  showfilter = false;
  allQuestion: boolean = true;
  draftedQuestion: boolean = false;
  totalItems: number;
  public openControlDetails = false;
  controlDetails: any;
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  pageSize: number = globalConstants.defaultPageSize;
  pageNumber: number = globalConstants.defaultPageNumber;
  data = [];
  drafted = [];
  columns: Partial<Column>[] = [];
  route: any;


  initColumns() {
    this.columns = [
      {
      iconColumn: true,
      elementAttribute: "positionOpenIcon",
      columnWidth: "2%",
    },
    {
      iconColumn: this.hasPermissionPipe.transform(this.route, 'canDelete'),
      elementAttribute: "positionDeleteIcon",
      columnWidth: '2%',
      hidden: !this.hasPermissionPipe.transform(this.route, 'canDelete')
    },
    {
      iconColumn: this.hasPermissionPipe.transform(this.route, 'canUpdate'),
      elementAttribute: "positionEditIcon",
      columnWidth: '2%',
      hidden: !this.hasPermissionPipe.transform(this.route, 'canUpdate')
    },
    {
      label: "SCF Domain",
      elementAttribute: "scfDomain",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Question",
      elementAttribute: "question",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Description",
      elementAttribute: "description",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
      isHtml: true,
    },
    {
      label: "Predefined Suggested Action",
      elementAttribute: "predefinedSuggestAction",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
      isHtml: true,
    },
    {
      label: "Document",
      elementAttribute: "document",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Chapter",
      elementAttribute: "chapter",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Control",
      elementAttribute: "control",
      columnClass: "matters-TypeWidth",
      isLink: false,
      onClickFn: this.onClickFn.bind(this),
      columnWidth: "200px",
    },
  ];
  }

  constructor(
    private questionService: QuestionService,
    public controlServices: ControlService,
    private activeRoute: ActivatedRoute,
    private hasPermissionPipe: HasPermissionPipe,
    private router: Router
  ) { }

  onClose(event) {
    this.showfilter = false;
  }

  onClickFn(element) {
    let controlId = element.controlId;
    this.isLoading = true;
    this.controlServices.get(controlId).subscribe((data) => {
      this.openControlDetails = true;
      console.log(data);
      this.controlDetails = data;
    });
    this.isLoading = false;
  }
  @HostListener("document:keydown.escape") close() {
    this.openDetails = false;
    this.openControlDetails = false;
  }
  applyFilter(event) {
    console.log(event);

    this.questionService.ApplyFilters(event).subscribe((res) => {
      this.data = res;
    });
  }

  showfilterfun() {
    this.showfilter = true;
  }
  faStar = faStar;
  faPlus = faPlus;
  Question: QuestionsViewModel;
  openDetails = false;
  onOpenDetails(event) {
    this.isLoading = true;
    this.questionService.get(event.id).subscribe((data) => {
      if (data) {
        this.openDetails = true;
        setTimeout(() => {
          this.Question = data;
        }, 200);
      }
      this.isLoading = false;
    },
      (error: any) => {
        this.isLoading = false;
        console.log(error);
      });
  }
  onCloseDetail(event) {
    this.openDetails = false;
    this.openControlDetails = false;
  }
  getEditUrl(event) {
    if (event?.id) this.router.navigate(["/Audit/Question/" + event.id]);
  }
  onDelete(event) {
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        if (event.linked) {
          this.questionService
            .deleteLinkedQuestion(event.linkedQId)
            .subscribe();
        } else {
          this.questionService.delete(event.id).subscribe();
        }
        Swal.fire("Deleted!", "Selected record has been deleted.", "success");
        this.isLoading = true;
        this.questionService.getAll({
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        }).subscribe(
          (Data) => {
            this.data = Data.data;
            this.totalItems = Data.count;
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
          }
        );
        this.questionService.getAllDrafted().subscribe(
          (Data) => {
            this.drafted = Data;
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.route = this.activeRoute.snapshot;
    this.initColumns();

    this.questionService.getAll({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    }).subscribe(
      (Data) => {
        this.data = Data.data;
        this.totalItems = Data.count;
        console.log(Data);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
    this.questionService.getAllDrafted().subscribe(
      (Data) => {
        this.drafted = Data;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
  getAll() {
    this.isLoading = true;
    this.questionService.getAll({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    }).subscribe(
      (Data) => {
        this.data = Data.data;
        this.totalItems = Data.count;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
  refresh() {
    this.getAll();
  }
  ChangeTable() {
    if (this.allQuestion) {
      this.allQuestion = false;
      this.draftedQuestion = true;
    } else {
      this.allQuestion = true;
      this.draftedQuestion = false;
    }
  }

  pageChangeHandler(event: PageEvent) {
    this.pageSize = event.pageSize;
    if (event.previousPageIndex < event.pageIndex) {
      this.pageNumber = event.pageIndex === 0 ? 1 : event.pageIndex + 1;
    } else if (event.previousPageIndex > event.pageIndex) {
      this.pageNumber = event?.previousPageIndex === 0 ? 1 : event.previousPageIndex;
    }
    this.isLoading = true;
    this.questionService.getAll({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    }).subscribe(
      (Data) => {
        this.data = Data.data;
        this.totalItems = Data.count;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );

    console.log(event)
  }
}
